body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #2054ac !important;
  text-decoration: none !important;
}
a:hover {
  color: #000 !important;
  text-decoration: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.homeBox {
  background: #00539f;
  /* background: linear-gradient(90deg, #00539F, #00539f); */
}

.bg-primary {
  background: #00539f;
  /* background: linear-gradient(90deg, #00539F, #00539f); */
}
.text-primary {
  color: #dde8e8 !important;
}
.homeBox2 {
  background-color: #08549c;
}
.homeBox3 {
  background-color: #08549c;
  border-radius: 10px;
}

.colorWhite {
  color: #fff;
}
.rateList {
  font-size: larger;
}

.logo {
  max-width: 100%;
  max-height: 60px;
}

.login-page {
  width: 100%;
  /* height: calc(80px -100vh); */
  display: inline-block;
  display: flex;
  align-items: center;
}

.form-control {
  height: 48px;
}

.thanks_bg {
  background: #dde8e8;
  height: 100vh;
}
.thanks_box {
  background: url(assets/image/wave_bg.svg) no-repeat 0 120% #fff;
  border-radius: 20px;
  position: relative;
  min-height: 560px;
  min-width: 600px;
  max-width: 800px;
  margin: 50px 30px;
  width: 100%;
}
.thanks_box:after {
  content: "";
  background: #dde8e8;
  border-radius: 20px;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: 94%;
  z-index: 0;
  height: 100%;
  margin: auto;
}
.thanks_box_content {
  width: 100%;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 9;
}

.thanks_bg2 {
  background: #dde8e8;
  height: 100vh;
}
.thanks_box2 {
  background: url(assets/image/wave_bg.svg) no-repeat 0 120% #fff;
  border-radius: 20px;
  position: relative;
  min-height: 800px;
  min-width: 600px;
  max-width: 800px;
  margin: 50px 30px;
  width: 100%;
}
.thanks_box2:after {
  content: "";
  background: rgba(211, 224, 224, 1);
  border-radius: 20px;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: 94%;
  z-index: 0;
  height: 100%;
  margin: auto;
}
.thanks_box_content2 {
  width: 100%;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 9;
}

.text_green {
  color: #5cc4bb;
}
.btn_success {
  background: #5cc4bb !important;
  border-color: #5cc4bb !important;
}

.nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #00539f !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #00539f !important;
}

@media (max-height: 767px) {
  .thanks_bg {
    height: 100% !important;
    padding: 50px;
  }
}
@media (max-width: 575px) {
  .thanks_box {
    min-width: 300px;
    margin: 15px;
    height: auto;
  }
}
.textDark {
  color: #00539f !important;
}
.textLight {
  color: #fff !important;
}
.light_gradient {
  background: #fff !important;
  /* background: linear-gradient( */
  /* 90deg,
    rgba(221, 232, 232, 1) 0%,
    rgba(211, 224, 224, 1) 100%
  ); */
}

.dark_gradient {
  background: #00539f;
  /* background: linear-gradient(90deg, #00539F 0%, rgb(52, 49, 59) 100%); */
}
.link_white {
  color: #fff !important;
}
.link_white:hover {
  color: #fff !important;
  text-decoration: underline !important;
}
.btn_dark {
  background-color: #00539f !important;
  /* background: linear-gradient(90deg, #00539F 0%, rgb(52, 49, 59) 100%); */
  color: #fff !important;
  padding: 7px 20px !important;
}
.btn_dark:hover {
  opacity: 0.9;
}

.back_btn {
  background: #fff !important;
  /* background: linear-gradient( */
  /* 90deg,
    rgba(221, 232, 232, 1) 0%,
    rgba(211, 224, 224, 1) 100%
  ); */
  color: #00539f;
  font-size: 18px;
  font-weight: 700;
  padding: 8px 25px;
  border: none;
  border-radius: 2px;
  min-width: 180px;
}
.forward_btn {
  background: #fff !important;
  /* background: linear-gradient(
    90deg,
    rgba(221, 232, 232, 1) 0%,
    rgba(211, 224, 224, 1) 100%
  ); */
  color: #00539f;
  font-size: 18px;
  font-weight: 700;
  padding: 8px 25px;
  border: none;
  border-radius: 2px;
  min-width: 180px;
}
.survey_link {
  color: #fff !important;
  text-decoration: none;
  font-size: 20px;
}
.back_btn:hover {
  opacity: 0.9;
}
.back_btn i {
  font-size: 22px;
  vertical-align: middle;
  transition: transform 0.5s ease;
  position: relative;
  top: -2px;
}
/* custom button  */

.btn-colour-1 {
  color: #fff !important;
  background-color: #00539f !important;
  border-color: #00539f !important;
  font-weight: bold !important;
  letter-spacing: 0.05em !important;
  border-radius: 4px !important;
}
.tipBtn {
  min-height: 100px;
  max-width: 100px !important;
}

.tipBtnPrice {
  min-width: 150px;
}

body {
  padding: 0 0;
  font-family: Roboto, sans-serif;
}

h1 {
  font-weight: 300;
  margin-bottom: 40px;
}

p {
  line-height: 20px;
  font-weight: 400;
}

@media (max-width: 575px) {
  .back_btn {
    width: 100%;
  }
  .logo {
    max-height: 30px;
  }
}
